import React from "react"
import {Prestavi} from "prestavi-website-react"

const styles = require("../styles/pages/Contact.module.scss");

export default function ContactPage() {

    return (
        <div className={styles.Contact}>
            <div className={styles.PageTitle}>
                <h1>We'd love to hear from you</h1>
                <p>We will try our best to respond to you within 24 hours.</p>
            </div>

            <Prestavi id="bvqqq4f8l1cpqkue00003e6avmitd88c" />

        </div>
    )
}

